<template>
  <section id="bot-filter-wrapper">
    <label for="bot-filter">
      {{
        multipleSelect
          ? lang.metrics.filters.bots.label[languageSelected]
          : lang.metrics.filters.bot.label[languageSelected]
      }}
    </label>

    <multiselect
      :key="`multiselect-${key.getTime()}`"
      v-model="value"
      tag-placeholder=""
      :placeholder="lang.filters.bot.placeholder[languageSelected]"
      :selected-label="lang.filters.bot.selectedLabel[languageSelected]"
      :deselect-label="lang.filters.bot.deselectLabel[languageSelected]"
      label="name"
      track-by="code"
      :options="options"
      :multiple="multipleSelect"
      :taggable="true"
      :limit="1"
      :limit-text="limitText"
      :show-labels="false"
      @input="change"
    />
    <!-- @tag="addTag" -->

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.clear[languageSelected] }}
    </vs-button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import konecta from '@/services/konecta'
import services from '../../../survey-data-view/services'
import { USER_ROLES } from '@/models/Roles/UserRoles'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'BotNoVersionFilter',
  props: {
    multipleSelect: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    startAll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      isLoading: false,
      key: new Date(),
      bots: [],
      value: [],
      options: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement'])
  },
  methods: {
    limitText(count) {
      return `+${count}`
    },
    change() {
      let value
      if (this.multipleSelect) {
        value = this.value.length ? this.value : this.options

        this.$emit('change', {
          type: 'botNoVersion',
          value: value.map(bot => bot.code)
        })
      } else {
        value = this.value

        this.$emit('change', {
          type: 'botNoVersion',
          value: value ? [value.code] : null
        })
      }
    },
    onlyAgent() {
      return (
        this.session.user.roles.ids.indexOf(USER_ROLES.AGENT) > -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPERVISOR) === -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPER_ADMIN) === -1
      )
    },
    requireActiveVersion() {
      return !this.$route.path.includes('/attention-requests')
    },
    async getBots() {
      this.$vs.loading()
      this.isLoading = true

      let query = this.onlyAgent() ? `?as[role]=${USER_ROLES.AGENT}` : ''
      if (
        this.useStaffManagement
        && !this.isAuthorized({ key: 'konecta.bot_maker.edit_bot_settings' })
      ) {
        query = `?as[role]=${USER_ROLES.AGENT}`
      }

      //await konecta.get(`/bot/active-version`)
      const response = this.requireActiveVersion()
        ? await services.getBotsActiveVersions()
        : await konecta.get('/bot' + query)

      this.bots = response.data.map(element => ({
        _id: element._id,
        serviceId: element.service._id,
        name: element.service.name,
        _updatedAt:
          element.service._updatedAt ||
          element._updatedAt ||
          element.service._createdAt ||
          element._createdAt
      }))

      // this.hasBots = this.bots.length > 0

      if (
        !this.$route.query.services ||
        this.$route.query.services.length === 0
      ) {
        this.options = this.bots.map(element => ({
          name: element.name,
          code: element.serviceId
        }))
        // this.value = this.options
      } else {
        const services = this.bots.map(element => element.serviceId)
        const filtered = (typeof this.$route.query.services === 'string'
          ? [this.$route.query.services]
          : this.$route.query.services
        ).filter(element => {
          return services.indexOf(element) > -1
        })
        this.options = []
        this.value = []
        this.bots.forEach(element => {
          if (filtered.indexOf(element.serviceId) > -1) {
            this.value.push({
              name: element.name,
              code: element.serviceId
            })
          }
          this.options.push({
            name: element.name,
            code: element.serviceId
          })
        })
      }

      this.key = new Date()
      this.$vs.loading.close()
      this.isLoading = true
    },
    reset() {
      this.value = []
    },
    clear() {
      this.reset()
      this.change()
    },
    selectBot(serviceId) {
      this.value = []
      const bot = this.options.find(b => b.code === serviceId)
      if (this.multipleSelect) {
        this.value.push(bot)
      } else {
        this.value.push(bot.code)
      }
    }
    // addTag(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
    //   }
    //   this.options.push(tag)
    //   this.value.push(tag)
    // }
  },
  async mounted() {
    await this.getBots()

    // order bots alphabetically
    this.options.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      return 0
    })
    if (this.startAll) {
      const botMap = this.options.map(bot => bot.code)
      this.$emit('init', {
        type: 'botNoVersion',
        value: botMap
      })
    } else if (!this.allowEmpty) {
      this.value = this.options[0]
      const botMap = this.options.map(bot => bot.code)

      this.$emit('init', {
        type: 'botNoVersion',
        value: [botMap[0]]
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#bot-filter-wrapper {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}

.multiselect__tags {
  min-height: 43px;
  max-height: 75px;
  width: 320px;
  overflow-y: auto;
}
.multiselect__tag {
  background: #ff9e43 !important;
}
.multiselect__tag-icon {
  background: #ff9e43 !important;
}
.multiselect__tag-icon:hover {
  background: #ff8102 !important;
}
.multiselect__tag-icon:after {
  color: white !important;
}
</style>
